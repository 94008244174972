(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/game-guide/assets/javascripts/nav-menu.js') >= 0) return;  svs.modules.push('/modules/oddset/components/game-guide/assets/javascripts/nav-menu.js');
"use strict";


const {
  ItemsMenu,
  LinkItem
} = svs.ui.ReactItemsMenu;
const {
  Link
} = ReactRouterDOM;
const {
  useLocation
} = ReactRouterDOM;
const {
  constants: {
    gameGuideRoutes
  }
} = svs.oddset.components.game_guide;
const GameGuideMenu = () => {
  const location = useLocation();
  return React.createElement(ItemsMenu, {
    centered: true,
    className: "oddset-game-guide-menu",
    customer: "sport",
    hiddenScrollbar: true
  }, gameGuideRoutes.map(item => React.createElement(LinkItem, {
    Component: Link,
    key: item.path,
    label: item.label,
    selected: location.pathname === item.path,
    to: item.path
  })));
};
setGlobal('svs.oddset.components.game_guide.GameGuideMenu', GameGuideMenu);

 })(window);