(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/game-guide/assets/javascripts/cubes-images.js') >= 0) return;  svs.modules.push('/modules/oddset/components/game-guide/assets/javascripts/cubes-images.js');
"use strict";


const {
  useRef,
  useEffect
} = React;
const {
  cdn
} = svs.core.config.data.svsconfig;
const timelineConfig = (tl, element) => {
  const randomPositiveZDegree = () => "".concat(Number(gsap.utils.random(0, 30)) % 2 ? '+' : '').concat(Number(gsap.utils.random(0, 30)), "deg");
  tl.to([element], {
    x: 0,
    y: 0,
    rotate: randomPositiveZDegree(),
    duration: 17,
    delay: 0.3,
    ease: 'back.out(1.4)',
    stagger: 0.3
  }).to([element], {
    x: -27,
    y: 12,
    rotate: randomPositiveZDegree(),
    duration: 12,
    delay: 0,
    ease: 'back.out(1.4)',
    stagger: 1.4
  }, '<').to([element], {
    x: 0,
    y: 0,
    rotate: randomPositiveZDegree(),
    duration: 20,
    ease: 'back.out(1.4)',
    stagger: -1.7
  }, '<');
};
const animate = _ref => {
  let {
    topIcon,
    bottomIcon
  } = _ref;
  const initZDegree = () => "".concat(Number(gsap.utils.random(20, 90)) % 2 ? '-' : '').concat(Number(gsap.utils.random(15, 90)), "deg");
  const isReducedMotionPreferred = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  const tlTop = gsap.timeline();
  const tlBottom = gsap.timeline();
  if (!isReducedMotionPreferred) {
    gsap.set([topIcon], {
      y: -10,
      rotationZ: initZDegree
    });
    gsap.set([bottomIcon], {
      y: 20,
      rotationZ: initZDegree
    });
    timelineConfig(tlTop, topIcon);
    timelineConfig(tlBottom, bottomIcon);
  } else {
    tlTop.to([topIcon, bottomIcon], {
      x: 0,
      y: 0,
      rotate: 0,
      duration: 1.5,
      delay: 0,
      ease: 'back.out(1.4)',
      stagger: 0
    });
    tlBottom.to([topIcon, bottomIcon], {
      x: 0,
      y: 0,
      rotate: 0,
      duration: 1.5,
      delay: 0,
      ease: 'back.out(1.4)',
      stagger: 0
    });
  }
};
const ImageHandler = _ref2 => {
  let {
    url,
    alt,
    className,
    elRef
  } = _ref2;
  return React.createElement("picture", {
    className: className,
    ref: elRef
  }, React.createElement("source", {
    media: "(min-width: 468px)",
    srcSet: "".concat(url, "@2x.png?v=2 3x, ").concat(url, "@1x.png?v=2 2x, ").concat(url, "@1x.png?v=2")
  }), React.createElement("img", {
    alt: alt,
    src: "".concat(url, "@1x.png?v=2"),
    srcSet: "".concat(url, "@1x.png?v=2 2x")
  }));
};
const Cubes = () => {
  const topIcon = useRef(null);
  const bottomIcon = useRef(null);
  useEffect(() => {
    if (topIcon.current && bottomIcon.current) {
      animate({
        topIcon: topIcon.current,
        bottomIcon: bottomIcon.current
      });
    }
  }, []);
  return React.createElement("div", {
    className: "oddset-game-guide-cubes"
  }, React.createElement("div", {
    className: "oddset-game-guide-cubes__right"
  }, React.createElement(ImageHandler, {
    alt: "Svenska spel",
    className: "oddset-game-guide-cubes__right--img-oddset",
    elRef: topIcon,
    url: "".concat(cdn, "/images/oddset/components/game-guide/oddset")
  })), React.createElement("div", {
    className: "oddset-game-guide-cubes__left"
  }, React.createElement(ImageHandler, {
    alt: "Svenska spel",
    className: "oddset-game-guide-cubes__left--img-oddset",
    elRef: bottomIcon,
    url: "".concat(cdn, "/images/oddset/components/game-guide/oddset")
  })));
};
setGlobal('svs.oddset.components.game_guide.Cubes', Cubes);

 })(window);